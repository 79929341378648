/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/embla-carousel@7.0.9/embla-carousel.umd.min.js
 * - /npm/embla-carousel-autoplay@7.0.9/embla-carousel-autoplay.umd.js
 * - /npm/vanilla-lazyload@17.8.3/dist/lazyload.min.js
 * - /npm/sticky-kit@1.1.3/dist/sticky-kit.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
